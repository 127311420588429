import React from "react";
import SEO from "../components/seo";
import FunctionPageLayout from "../components/functionPageLayout";
import ArticleSquare from "../components/articleSquare";

const WiedzaOCrm = () => {
  return (
    <FunctionPageLayout
      title="Wiedza o CRM"
      headline="Przydatne porady i wskazówki dotyczące systemu CRM"
    >
      <SEO
        title="CRM - blog. Kopalnia informacji dla spragnionych wiedzy"
        description="Baza wiedzy o systemie CRM • Poradnik dla małej firmy • Materiały video - zobacz na własne oczy, jak CRM może wpłynąć na funkcjonowanie Twojej firmy!"
      />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap"
        }}
      >
         <ArticleSquare
          title="Proces budowy sieci stałych odbiorców końcowych"
          link="/proces-budowy-sieci-stalych-odbiorcow-koncowych-w-crm/"
        />
        <ArticleSquare
          title="Jak zorganizować proces sprzedaży pośredniej w CRM?"
          link="/budowa-kanalu-sprzedazy-posredniej/"
        />
       <ArticleSquare
          title="Jak nie tracić szans sprzedaży?"
          link="/proces-sprzedazy-w-crm/"
        />
        <ArticleSquare
          title="Proces pozyskiwania dystrybutorów"
          link="/proces-pozyskiwania-dystrybutorow/"
        />
        <ArticleSquare
          title="Usprawnianie procesu sprzedaży"
          link="/usprawnianie-procesu-sprzedazy/"
        />
        <ArticleSquare
          title="Pierwsze kroki w CRM"
          link="/pierwsze-kroki-w-crm/"
        />
        <ArticleSquare
          title="Proces sprzedaży projektowej w systemie CRM"
          link="/proces-sprzedazy-projektowej/"
        />
        <ArticleSquare
          title="CRM dla firm: najważniejsze pytania o system dla MŚP"
          link="/crm-dla-firm/"
        />
        <ArticleSquare
          title="Na czym polega filozofia CRM?"
          link="/filozofia-crm/"
        />
        <ArticleSquare
          title="Jak wybrać program CRM, który nie będzie Cię ograniczał w przyszłości?"
          link="/program-crm/"
        />
        <ArticleSquare
          title="Mobilny CRM w Twojej firmie"
          link="/mobilny-crm/"
        />
        <ArticleSquare
          title="Jak dobry CRM może pomóc Ci w zdobywaniu nowych klientów?"
          link="/dobry-crm/"
        />
        <ArticleSquare
          title="Jak działa system CRM?"
          link="/jak-dziala-system-crm/"
        />
        <ArticleSquare
          title="Skuteczny proces sprzedaży w&nbsp;5 krokach"
          link="/skuteczny-proces-sprzedazy/"
        />
        <ArticleSquare
          title="Jak zadowolić swoich klientów i rozwijać biznes? CRM dla małych firm."
          link="/crm-dla-malych-firm/"
        />
        <ArticleSquare
          title="Mobilny CRM, który zwiększy Twoją sprzedaż"
          link="/mobilny-crm-sprzedaz/"
        />
        <ArticleSquare
          title="Mity o CRM, czyli jak nie myśleć o zarządzaniu relacjami z klientem"
          link="/mity-o-crm/"
        />
        <ArticleSquare
          title="Prosty CRM dla handlowca"
          link="/prosty-crm-dla-handlowca/"
        />
        <ArticleSquare
          title="Dane osobowe w CRM. Co musisz wiedzieć?"
          link="/dane-osobowe-w-crm/"
        />
        <ArticleSquare
          title="Aplikacja CRM. Prowadzenie firmy nigdy nie było tak proste"
          link="/aplikacja-crm/"
        />
        <ArticleSquare
          title="Jak wygrywać więcej szans sprzedaży, wykorzystując system CRM?"
          link="/jak-wygrywac-wiecej-szans-sprzedazy-wykorzystujac-system-crm/"
        />
        <ArticleSquare
          title="Excel czy CRM? 8 powodów, dla których warto zamienić Excela na system CRM"
          link="/6-powodow-dla-ktorych-warto-zamienic-excela-system-crm/"
        />
        <ArticleSquare
          title="Jak zaplanować niezawodny proces sprzedaży. Etapy procesu sprzedaży."
          link="/jak-zaplanowac-proces-sprzedazy/"
        />
        <ArticleSquare
          title="Jaki CRM dla małej firmy?"
          link="/jaki-crm/"
        />
        <ArticleSquare
          title="11 powodów, dla których warto wdrożyć program CRM w firmie"
          link="/dlaczego-program-crm/"
        />
        <ArticleSquare
          title="CRM dla małej firmy"
          link="/crm-dla-malej-firmy/"
        />
        <ArticleSquare
          title="5 narzędzi online, które pozwolą Twojej firmie ruszyć z kopyta"
          link="/program-dla-malej-firmy/"
        />
        <ArticleSquare
          title="Jak oprogramowanie CRM może wpłynąć na rozwój Twojej firmy?"
          link="/oprogramowanie-crm/"
        />
        <ArticleSquare
          title="CRM w chmurze – idealny od początku"
          link="/crm-w-chmurze/"
        />
      </div>
    </FunctionPageLayout>
  );
};

export default WiedzaOCrm;
